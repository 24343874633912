.content-displayer {
  position: relative;
  height: 100vh;
}

.container-floatting-down-right {
  position: fixed !important;
  bottom: 40px;
  right: 40px;
}

.container-floatting-down-left {
  position: fixed;
  bottom: 40px;
  left: 0px;
}

.container-floatting-up {
  position: absolute;
  top: 10px;
  left: 0px;
}

.hidden-content {
  display: none !important;
}

.container-icon-customization-1 {
  color: white;
}

.toast-custom-styled {
  position: relative;
  min-height: 200px;
  z-index: 9999999;
}

/* .toast-custom-styled .toast{
    position: relative; 
    bottom: 1; 
    left: 1;
} */

.page-header-divider {
  width: 100%;
  height: 30px;
  border-top: 10px solid;
  /* margin: 0 10px 0 10px; */
  color: #8c8b8b;
}

.page-container {
  max-width: 100vw;
  overflow: hidden;
  height: 100vh;
}

.big-title {
  font-weight: bold;
}

.card-graphe {
  width: 49% !important;
  height: 50vh !important;
  display: inline-flex;
}

.Graphe {
  text-align: center;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  background-color: transparent !important;
  /* border: 1px blue solid; */
  border-radius: 5px;
  flex-grow: 1 !important;
}

.leaflet-spacing {
  margin-top: -100px;
}

.leaflet-map-pane {
  margin-top: 60px;
}

.tab-name-style {
  max-width: 200px;
}

.task-list-tbody {
  position: relative;
  z-index: 1;
}

.parent-task-line {
  z-index: 2;
}

.sub-task-line {
  margin-right: 0px;
}

.child-task-show {
  display: block;
}

.display-task-child-icon {
  -webkit-text-stroke: 1px;
}

.child-task-line {
  width: 1.5rem !important;
  background-color: coral;
}

.arrow-show-children {
  display: inline-block;
  transition: all 0.2s ease;
  transition: transform 0.2s ease;
}

.arrow-show-children:last-of-type {
  right: 0;
  transform: rotate(-45deg);
  transform: rotate(45deg);
}

.arrow-show-children:first-of-type {
  transform: rotate(-45deg);
}

.arrow-show-children:last-of-type {
  transform: rotate(45deg);
}

.active-arrow-show-children:first-of-type {
  transform: rotate(-45deg);
}

.active-arrow-show-children:last-of-type {
  transform: rotate(45deg);
}

.arrow-show-children {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  /*margin: 0 1rem;*/
}

.arrow-show-children span {
  top: 0.5rem;
  position: absolute;
  width: 0.75rem;
  height: 0.1rem;
  background-color: #efefef;
  display: inline-block;
  transition: all 0.2s ease;
}

.arrow-show-children span:first-of-type {
  left: 0;
  transform: rotate(45deg);
}

.arrow-show-children span:last-of-type {
  right: 0;
  transform: rotate(-45deg);
}

.arrow-show-children.active-arrow-show-children span:first-of-type {
  transform: rotate(-45deg);
}

.arrow-show-children.active-arrow-show-children span:last-of-type {
  transform: rotate(45deg);
}

.intervals {
  border: blue 5px solid;
  border-radius: 10px;
}

.dropdownColumn {
  overflow-y: scroll;
  max-height: 200px;
}

#description-select-label-fixed {
  top: -7px;
}

/* .not-scrollable{
    height: 100% !important;
    overflow: hidden !important;
  } */

.topBar {
  position: sticky;
  top: 0px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 15px;
}

.sidebar.sidebar-reduction {
  width: 80px;
}

.sidebar.sidebar-reduction ul {
  margin-top: 20px;
}

.carte {
  height: 80vh !important;
  display: block ruby;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

.marker-pin::after {
  content: "";
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.custom-div-icon i {
  position: absolute;
  width: 22px;
  font-size: 22px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}

.fa-solid {
  font-size: 17px !important;
  margin-top: 1px !important;
}

@media (max-width: 768px) {
  .sidebar.sidebar-reduction {
    position: fixed;
    left: -100% !important;
    top: 0;
    z-index: 2000;
    width: 280px !important;
    /*left: 0 !important;*/
  }
}
