@mixin logoSize($size : 25px){
    width: $size;
    height: $size;
}

.LoginPage{
    margin: 0px;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: linear-gradient(rgba(71, 142, 165, 0.5),rgba(61, 110, 126, 0.4)) , url(../../assets/img/loginPageBackground.png) no-repeat;
    background-size: cover;
    background-position: 100%;

    .app-features{
        display: grid;
        padding-left: 20%;
        align-content: center;
        height: 80%;
        color: white;
        gap: 50px;

        .main-feature-title{
            align-content: center;
            font-size: 50px;
            font-weight: bold;
            letter-spacing: 3px;
        }

        .features{
            align-content: center;
            display: grid;
            gap: 15px;
            

            div{
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 25px;
                font-weight: bold;
                letter-spacing: 1px;

                span:nth-child(1){
                    background-color: white;
                    border-radius: 50%;
                    display: grid;
                    place-items: center;
                    padding: 7px;

                    img{
                        @include logoSize(20px);
                    }
                }
            }
        }

        

    
    }
    .login-space{
        display: grid;
        grid-template-rows: auto 7%;

        .login-container{
            place-self: center;
            padding: 10px 15px 50px 15px;
            border-radius: 50px;
            background-color: #f8fafb;

            .partners{
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 70px;

            }

            .spinner-border {
                // Inside the login form spinner must enforce padding
                // and dimensions
                padding: 0 !important;
                height: 24px;
                width: 24px;
            }

            form{
                width: 100%;
                display: grid;
                gap: 25px;

                div{
                    padding: 0 50px;
                    display: flex;
                    gap: 10px;

                    label{
                        background-color: white;
                        padding: 15px;
                        border-radius: 50px;
                        border: 1px solid #848484;
                        display: flex;
                        place-items: center;
                    }

                    input{
                        border-radius: 50px;
                        padding: 7px 25px;
                        outline: none;
                        border: 1px solid #cccccc;
                        width: 100%;
                        color: black;
                        font-weight: bold;
                    }
                }

                a{
                    width: 100%;
                    text-align: end;
                    padding: 0px 50px;
                    color: #1b5f7e;
                    text-decoration: none;
                    font-weight: bold;
                    margin-bottom: 30px;

                }

                .submit-button-container{
                    display: flex;
                    justify-content: center;

                    button{
                        width: 55%;
                        padding: 0 20px;
                        padding: 10px;
                        background-color: #1b5f7e;
                        color: white;
                        text-transform: uppercase;
                        border-radius: 50px;
                        border: none;
                        outline: none;
                        font-weight: bold;
                    }
                    
                }
            }
        }

        .developers-firm{
            width: 85%;
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-end;
            gap: 50px;
            color: white;

            div{
                display: flex;
                align-items: center;
                gap: 5px;
                
                img{
                    display: flex;
                }

            }
        }

    
    }
}

@media (max-width: 768px) {
    .LoginPage{
        grid-template-columns: repeat(1, 1fr);
    }
    .LoginPage .login-space .login-container form div {
        padding: 0 20px;
    }
    .LoginPage .login-space .login-container .partners > img {
        width: 80px;
    }
    .LoginPage .login-space {
        grid-template-rows: 7% auto 7%;
        justify-content: center;
    }
}
