@tailwind base;
@tailwind components;

.select-input-2 {
  width: 47% !important;
  display: inline flow-root;
  margin-left: 6%;
}
@tailwind utilities;

@layer base {
  .labeled-extended {
    display: block;
  }
}

.Button {
  background-color: #e7e7e7;
  color: black;
  border: none;
  padding: 7px 16px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.Switch {
  margin: 4px 15px;
  font-size: 14px;
  font-family: "Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue";
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: green; */
}

.Switch_Toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-right: 5px;
  border-radius: 10px;
}

.Switch_Toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  background-color: #ccc;
  line-height: 1.5;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
}

.Slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .Slider {
  background-color: #2196f3;
}

input:focus + .Slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .Slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch-text {
  font-weight: 400;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.top-right {
  position: absolute !important;
  top: 20px;
  right: 30px;
  min-width: 150px;
}

.scomp-radio {
  margin: 0 10px 0 10px;
  display: inline-flex;
}

.scomp-container {
  text-align: center;
}

.scomp-label {
  margin-left: 5px;
}

.react-datepicker-wrapper {
  display: inline-flex !important;
  width: fit-content !important;
}

.ant-select-selector,
.date-picker {
  height: 38px;
  border-radius: 5px;
  border: 1px solid rgb(204, 204, 204);
  overflow: hidden;
}

.ant-select-selection-placeholder {
  font-size: 1.2em;
  color: #808080 !important;
}

.anticon-close {
  vertical-align: 0 !important;
}

.select-wrapper {
  width: 70%;
  margin: auto;
  height: 39px;
}

.select-input {
  width: 15% !important;
  display: inline flow-root;
  margin-left: 10px;
}

.select-input-2:first-of-type {
  margin-left: 0%;
}

.date-picker {
  text-align: center;
  width: 100% !important;
}

.accordion-button,
.accordion-button > h4 {
  width: 100%;
  text-align: center !important;
}

.download {
  /* bottom: 50px; */
  font-size: x-large !important;
  padding: 0 !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}

.slim {
  width: 250px !important;
  height: 30px !important;
}

.label {
  position: absolute;
  right: 0;
  top: -5px;
  z-index: -1;
  width: 170px;
  height: 50px;
  border-radius: 5px;
  border: 2px solid black;
  background-color: rgba(53, 14, 14, 0.664);
  display: none;
}

.labeled {
  position: absolute;
  right: 0;
  z-index: 500;
  color: white;
  font-size: 1.1em;
}

.labeled:hover > .label {
  display: table !important;
}

.title-container {
  height: 52px;
  padding-top: 10px;
  background-color: #0c63e4;
  color: white;
  margin-bottom: 10px;
}

.region-selector-leaflet {
  height: 50vh !important;
}

.leaflet-tooltip.map-labels {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
}

.leaflet-control-attribution {
  display: none;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
}

.transition-pacofide {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.analyse-listing {
  overflow-y: scroll;
  max-height: 500px;
}

.chart-group-title {
  border-radius: 5px;
  padding: 5px 0;
  margin-bottom: 15px;
  background-color: #0d6efd;
  color: white;
}

.leaflet-popup {
  bottom: 27px !important;
  left: -172px !important;
}
