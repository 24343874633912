.profile-page{
    padding: 6% 2%;
    display: flex;
    justify-content: center;
    height: 100%;
    
    .profile-container{
        height: 520px;
        border-radius: 2%;
        box-shadow: 5px 5px 7px 5px rgb(238, 235, 235);

        .cover-picture{
            background: linear-gradient(275deg, rgb(37, 137, 224) 11%, rgb(140, 235, 231) 100%);
            width: 100%;
            height: 125px;
        }

        .picture-fullname{
            position: relative;
            left: 5%;
            top: 50%;
            text-align: center;
            
            img{
                border-radius: 50%;
                box-shadow: 2px 2px 3px rgb(206, 203, 203);
            }
        }
        
        .infos{
            margin-top: 100px;
            
            & > div{
                display: flex;
                gap: 15px;
                padding: 10px;

                div{
                    display: grid;
                    align-items: center;
                }
            }

        }
        
        button{
            background: linear-gradient(275deg, rgb(37, 137, 224) 11%, rgb(140, 235, 231) 100%);
            outline: none;
            border-style: none;
            border-radius: 25px;
            padding: 7px;
            text-transform: uppercase;
            font-weight: bold;
            color: white;

        }
    }
}
