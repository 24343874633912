.save-data-button{
    background: linear-gradient(275deg, rgb(37, 137, 224) 11%, rgb(140, 208, 235) 100%);
    outline: none;
    border-style: none;
    border-radius: 25px;
    padding: 7px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;

}